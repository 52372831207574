import { useEffect } from "react";
import { useRouter } from "next/router";
type Props = {};

const Error404 = (props: Props) => {
  const router = useRouter();
  // const detectDevice = () => {
  //   const isIphone = (/iphone|ipod/).test(navigator.userAgent.toLowerCase())
  //   const isAndroid = (/android/).test(navigator.userAgent.toLowerCase())
  //   if (isIphone) {
  //     window.location = 'https://apps.apple.com/id/app/sellon-online-marketplace/id1462394563'
  //   } else if(isAndroid) {
  //     window.location = 'https://play.google.com/store/apps/details?id=com.csi.sellon'
  //   } else {
  //     router.push("/landing/");
  //   }
  // }
  useEffect(() => {
    router.push("/landing/");
  }, []);
  
  // return <div className="h-screen w-screen flex items-center justify-center"> 
  //   Please wait, we are redirecting 
  // </div>;

  //for test
  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center">
      <div> Sorry, Page not Found !</div>
      <div> Please wait, we are redirecting </div>
    </div>
  );

}

export default Error404
